.Settings__block {
    display: flex;
    flex-direction: column;
    padding: 25px; }

.Settings__item {
    display: flex;
    margin: {
        top: 27px; }
    &:first-child {
        margin: {
            top: 0; } }
    align-items: center;
    font: {
        size: 13px;
        weight: 530;
        family: -apple-system, BlinkMacSystemFont, sans-serif; }
    &.not_line {
        flex-direction: column;
        align-items: flex-start; }

    .item {
        margin-top: 9px; }

    .switch {
        margin-left: 10px; } }

.Settings__buttom {
    margin-right: 8px; }

.Setting__blue_buttom__block {
    display: flex;
    margin: {
        top: 23px;
        bottom: 22px; }
    .item {
        cursor: pointer;
        color: #2A5885;
        margin: 0px 5px;
        font: {
            size: 13px;
            weight: 530;
            family: -apple-system, BlinkMacSystemFont, sans-serif; } }
    .right {
        margin: {
            right: 5px; } } }

.Settings__line {
    margin: {
        top: 25px; } }


.Settings__input {
    width: 100%; }

.Settings__constructor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 25px;

    &:hover {
        cursor: pointer;
        background-color: #F3F3F5; }

    .item {
        font: {
            size: 13px;
            weight: 530;
            family: -apple-system, BlinkMacSystemFont, sans-serif; } } }

.Settings__buttom__block {
    margin: {
        bottom: 20px; } }
