.Modal {
    background: #FFFFFF;
    border-radius: 5px 5px 4px 4px;
    height: fit-content;
    width: 450px;
    overflow: hidden; }

.close_modal {
    cursor: pointer; }

.BlueTitle__block {
    .Icon--cancel_16 {
        cursor: pointer; } }
