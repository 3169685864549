.ActiveStatus__block {
    display: flex;
    align-items: center; }
.ActiveStatus {
    padding: 13.5px 20px;
    color: #656565;
    font: {
        size: 13px;
        weight: 400;
        family: -apple-system, BlinkMacSystemFont, sans-serif; }

    border-bottom: {
            width: 2px;
            color: transparent;
            style: solid; }
    &.active {
        color: black;
        border-bottom: {
            width: 2px;
            color: #5181B8;
            style: solid; } } }

.PanelHeader__container {
    border-bottom: {
            width: 1px;
            color: #E7E8EC;
            style: solid; } }


