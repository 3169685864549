.BlueTitle {
    background-color: #5B88BD;
    height: 54px;
    width: 100%; }

.BlueTitle__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0px 25px;
    .BlueTitle__title {
        color: white;
        font: {
            size: 14px;
            weight: 500;
            family: -apple-system, BlinkMacSystemFont, sans-serif; } } }

.GreyFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: 58px;
    width: 100%;
    border-top: 1px  solid  #E7E8EC;

    background-color: #F7F8FA;
    .item {
        margin: {
            right: 22px; } } }

.Modal__content {
    padding: 21px 25px;
    padding-bottom: 0px;
    max-height: calc(100vh - 210px);
    overflow-y: auto;

    .item {
        display: flex;
        flex-direction: column;
        margin: {
            bottom: 27px; }
        .title {
            margin: {
                bottom: 7px; }
            font: {
                size: 13px;
                weight: 500;
                family: -apple-system, BlinkMacSystemFont, sans-serif; } }
        .input {
            width: 100%;
            margin: {
                bottom: 10px; }
            &.add {
                cursor: pointer; }
            & {} }
        .icon {
            z-index: 100;
            right: 8px;
            top: 7px;

            cursor: pointer; }
        &.type {
            margin: {
                bottom: 23px; } }
        &.last {
            margin: {
                bottom: 20px; } } }
    .line {
        width: 100%;
        height: 2px;
        background-color: #E7E8EC; } }


.radiobtn {
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 1.27em;
    padding: {
        bottom: 8px; }
    font: {
            size: 14px;
            weight: 400;
            family: -apple-system, BlinkMacSystemFont, sans-serif; }
    &:before {
            display: block;
            content: '';
            float: left;
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cdefs%3E%3Ccircle%20id%3D%22a%22%20cx%3D%227%22%20cy%3D%227%22%20r%3D%227%22%2F%3E%3C%2Fdefs%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cuse%20fill%3D%22%23FFF%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%3Ccircle%20cx%3D%227%22%20cy%3D%227%22%20r%3D%226.5%22%20stroke%3D%22%23C1C9D1%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat 0;
            width: 14px;
            margin: 1px 7px 1px 0;
            height: 14px; }
    &.on {
        &:before {
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cdefs%3E%3Ccircle%20id%3D%22a%22%20cx%3D%227%22%20cy%3D%227%22%20r%3D%227%22%2F%3E%3C%2Fdefs%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cuse%20fill%3D%22%23FFF%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%3Ccircle%20cx%3D%227%22%20cy%3D%227%22%20r%3D%226.5%22%20stroke%3D%22%235181B8%22%2F%3E%3Ccircle%20cx%3D%227%22%20cy%3D%227%22%20r%3D%223%22%20fill%3D%22%235181B8%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); } } }

.attach__block {
    display: flex;
    margin: {
        top: 18px; }
    .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: {
            right: 20px; }

        color: #2A5885;
        font: {
                size: 13px;
                weight: 400;
                family: -apple-system, BlinkMacSystemFont, sans-serif; }

        cursor: pointer;

        .icon {
            color: #728FB5;
            margin: {
                right: 6px; } } } }
