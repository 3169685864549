.Photo {
    margin: {
        right: 10px; }
    .item {
        position: relative;
        width: 160px;
        height: 100px;
        background: {
            position: center;
            size: cover; }
        border-radius: 4px;
        .remove {
            position: absolute;
            right: 4px;
            top: 4px;

            width: 20px;
            height: 20px;

            background-color: #000000;

            opacity: 0.45;
            border-radius: 3px;

            cursor: pointer;
            color: white;

            display: flex;
            align-items: center;
            justify-content: center; } } }


.Photos {
    display: flex;
    flex-flow: row wrap; }
