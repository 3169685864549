.Toggler {
    margin-top: 4px;
    width: 28px;
    height: 9px;
    border-radius: 45px;
    background-color: #ced7e0;
    cursor: pointer;
    &:after {
        content: '';
        float: left;
        position: relative;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        border: 1px solid #b4bfcc;
        background-color: #fff;
        top: -3px;
        left: 0;
        -o-transition: left 0.1s ease;
        transition: left 0.1s ease; }
    &.on {
        background-color: #bccde0;
        &:after {
            background-color: #5181b8;
            border-color: #5181b8;
            left: 13px; } } }
