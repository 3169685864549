.NoPollsInList {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100vh;

    .item {
        .text {
            color: #828282;
            text-align: center;
            margin: {
                bottom: 14px; }
            font: {
                size: 13px;
                weight: 530;
                family: -apple-system, BlinkMacSystemFont, sans-serif; } } } }


.Pointer {
    &:hover {
        cursor: pointer; } }
