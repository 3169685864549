.AddPoll__block {
    display: flex;
    padding: 10px 20px;
    align-items: center; }
.AddPoll__item {
    margin: {
        left: 6px; }
    font: {
        size: 13px;
        weight: 400;
        family: -apple-system, BlinkMacSystemFont, sans-serif; } }
