.Question {
    background-color: white;
    .header {
        display: flex;
        justify-content: space-between;
        background-color: white;
        width: 100%;
        height: 40px;
        padding: 0px 18px;
        border-bottom: {
                width: 1px;
                color: #E7E8EC;
                style: solid; }
        margin: {
            top: 12px; }
        .title {
            display: flex;
            align-items: center;
            color: #939393;
            .more_icon {
                color: #AAAEB3;
                cursor: pointer; }
            .text {
                margin: {
                    left: 15px; }
                font: {
                    size: 13px;
                    weight: 530;
                    family: -apple-system, BlinkMacSystemFont, sans-serif; } } }

        .actions {
            display: none;
            align-items: center;
            margin: {
                right: 30px; }
            .item {
                display: flex;
                align-items: flex-end;
                color: #2A5885;
                margin: 0px 5px;
                font: {
                    size: 13px;
                    weight: 400;
                    family: -apple-system, BlinkMacSystemFont, sans-serif; }
                cursor: pointer; } } }
    &:hover {
        .header {
            .actions {
                display: flex; } } }

    .main {
        padding: 16px 25px;
        .name {
            white-space: pre-wrap;
            margin: {
                bottom: 16px; }
            font: {
                size: 13px;
                weight: 550;
                family: -apple-system, BlinkMacSystemFont, sans-serif; } }
        .item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 41px;
            border-top: {
                    width: 1px;
                    color: #E7E8EC;
                    style: solid; }
            .action {
                display: flex;
                align-items: flex-end;
                color: #2A5885;
                font: {
                    size: 13px;
                    weight: 400;
                    family: -apple-system, BlinkMacSystemFont, sans-serif; }
                cursor: pointer;
                &:hover {
                    text-decoration: underline; } }
            .tooltip {
                top: 50px;
                right: -15px;
                width: 300px;
                height: fit-content;

                overflow: hidden;

                z-index: 119;
                border-radius: 4px;
                box-shadow: 0 1px 3px rgba(0,0,0,.1);
                background-color: #fff;
                position: relative;
                position: absolute;
                -o-transition: opacity 150ms ease-in-out, visibility 150ms ease-in-out 150ms;
                transition: opacity 150ms ease-in-out, visibility 150ms ease-in-out 150ms;


                background: #FFFFFF;
                box-shadow: 0 20px 40px 0 rgba(0,0,0,0.32);
                border-radius: 8px;
                letter-spacing: -0.08px;
                line-height: 19px;
                .actions {
                    display: flex;
                    flex-direction: column;
                    .action {
                        padding: 7px 12px;
                        color: black;
                        font: {
                            size: 13px;
                            weight: 400;
                            family: -apple-system, BlinkMacSystemFont, sans-serif; }
                        cursor: pointer;
                        &:hover {
                            background-color: #E7EDF1; } } } } } } }


